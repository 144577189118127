import { useState, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { axiosInstance as api } from '../../utils/server'
import { Link } from "react-router-dom"
import { strings } from '../../lang/Lang'


function Index(props) {
    const [companies, setCompanies] = useState()
    const [name, setName] = useState()
    const [email, setEmail] = useState()

    useEffect(async () => {
        setName(await AsyncStorage.getItem('@user_name'))
        setEmail(await AsyncStorage.getItem('@user_email'))
        login()
        getCompanies()
    }, [])

    const login = async () => {
        const login = await AsyncStorage.getItem('@user_token')
        if (!login) {
            props.history.push('/login')
        }
    }

    const logOut = async () => {
        await AsyncStorage.removeItem('@user_token')
        await AsyncStorage.removeItem('@user_id')
        await AsyncStorage.removeItem('@user_authority')
        await AsyncStorage.removeItem('@user_name')
        await AsyncStorage.removeItem('@user_email')
        window.location.reload()
    }

    const getCompanies = async () => {
        const user_id = await AsyncStorage.getItem('@user_id')
        const response = await api.get('api/companies', {
            params: {
                users_ID: user_id
            }
        })
        if (response.data.status) {
            setCompanies(response.data)
        }
    }

    return (
        <>

            <h1 className="h3 mb-4 text-gray-800">
                <b>{strings.welcome} {name} - {email}
                    <button onClick={() => logOut()} className="btn btn-danger btn-icon-split log-out mr-3">
                        <span className="text action-button">{strings.logOut}</span>
                    </button>
                </b>
                <br />
                <p className="mt-3">{companies ? companies.data.length > 0 ? strings.selectCompany : null : null}</p>
            </h1>

            <ul className="companies-list">
                {
                    companies ?
                        companies.data.map(element => {
                            return (
                                <li key={element._id} className="nav-item">
                                    {
                                        element.disabled ? // devre dışı bırakıldı sayfasına gönder
                                            <Link className="nav-link company-name" to={{
                                                pathname: '/edit-company', state: {
                                                    id: element._id,
                                                    name: element.name,
                                                    date: element.created_at,
                                                    trial: element.trial
                                                }
                                            }}>
                                                <h4>{element.name} - <i>{strings.disabed}</i></h4>
                                            </Link> :

                                            <Link className="nav-link company-name" to={{
                                                pathname: '/company', state: {
                                                    id: element._id,
                                                    name: element.name,
                                                    categories: element.categories
                                                }
                                            }}>
                                                <h4>{element.name}</h4>
                                            </Link>
                                    }
                                </li>
                            )
                        }) : null
                }
            </ul>
            <br />
            <p>
                {strings.support}:  <a className="nav-link" href="mailto:contact@teknozip.com">
                    <span className="pointer">contact@teknozip.com</span>
                </a>
            </p>
        </>
    )
}

export default Index