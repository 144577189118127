import { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import AsyncStorage from '@react-native-async-storage/async-storage'


function Menu() {
    const [token, setTokenStatus] = useState(false)
    const [admin, setAdmin] = useState(false)


    useEffect(async () => {
        const login = await AsyncStorage.getItem('@user_token')
        const admin = await AsyncStorage.getItem('@user_authority')
        setTokenStatus(login)
        setAdmin(admin)
    })

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to={{ pathname: '/' }}>
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-code"></i>
                </div>
                <div className="sidebar-brand-text mx-2 teknozip">teknozip.com</div>
            </Link>

            <hr className="sidebar-divider my-0" />

            {
                token ?
                    <>
                        <li className="nav-item">
                            <Link className="nav-link" to={{ pathname: '/' }}>
                                <i className="fas fa-home mr-2"></i>
                                <span>Homepage</span>
                            </Link>
                        </li>

                        {
                            admin ? 
                            <>
                            <li className="nav-item">
                            <Link className="nav-link" to={{ pathname: '/add-company' }}>
                                <i className="fas fa-building mr-2"></i>
                                <span>Add Company</span>
                            </Link>
                        </li>
                        </>
                        : null
                        }
                        
                       
                    </>
                    : <li className="nav-item">
                        <Link className="nav-link" to={{ pathname: '/' }}>
                            <i className="fas fa-home mr-2"></i>
                            <span>Login</span>
                        </Link>
                    </li>
            }

            <hr className="sidebar-divider" />
            <div className="sidebar-heading">
            </div>
        </ul>
    )
}


export default Menu
