import LocalizedStrings from 'react-localization';

export let strings = new LocalizedStrings({
    en: {
        save: "Save",
        adminPanelLanguage: "Admin Panel Language",
        welcome: "Welcome",
        selectCompany: "Select the company you want to manage.",
        support: "Support",
        manageCompanies: "Manage Companies",
        foodMenuLang: "Food Menu Languages",
        defaultMenuLang: "Default Food & Category Adding Language",
        companyName: "Company Name",
        chooseFoodMenuLang: "Food Menu Languages",
        addCompany: "Add Company",
        companyLangInfo: "when creating products and categories, it adds it to the menu in the language you choose by default. you can access other languages later in the edit product menu.",
        paidVersion: "Your business is in the paid version. Next payment date: ",
        freeTrial: "Your business is on a free 15-day trial.",
        nonPayment: "Your business has been disabled for non-payment.",
        paymentDetails: "Payment Details",
        paymentDeadline: "Your business's payment deadline has passed. Please pay.",
        toContinue: "To continue using",
        categories: "Categories",
        goWebsite: "Go to Website",
        createCategory: "Create Category",
        saveCategoryOrder: "Save Category Customize",
        categoryOrderInfo: "You can customize the categories/products with swipe",
        categoryName: "Category Name",
        categoryImage: "Upload image",
        fillData: "Be sure to fill in the required data.",
        creating: "creating...",
        updating: "updating...",
        updateCategory: "Update Category",
        addProduct: "Add Product",
        saveProductOrder: "Save Product Customize",
        editCategory: "Edit Category",
        deleteCategory: "Delete Category",
        back: "Back",
        orderUpdated: "Customize updated",
        somethingWentWrong: "Something went wrong",
        noOrderChange: "No sequence change was detected",
        category: "Category",
        product: "Product",
        productName: "Product Name",
        productPrice: "Product Price",
        productDetails: "Product Details",
        addProduct: "Add Product",
        updateProduct: "Update Product",
        deleteProduct: "Delete Product",
        logOut: "Log out",
        disabed: "disabled",
        logIn: "Log in",
        register: "Register",
        email: "E-mail",
        password: "Password",
        name: "Name",
        haveAcc: "Do you have an account? Sign in to manage their business. ",
        goRegister: "Not account yet? Sign up to try it free for 15 days. ",
        businessLimit: "You've reached the free business add limit.",
        unauthorizedAccess: "Unauthorized Access Detected!",
        deleteProductsFirst: "You must delete the products first",
    }
});