import { useEffect, useState } from 'react'
import { axiosInstance as api } from '../../utils/server'
import { Link } from "react-router-dom"
import AsyncStorage from '@react-native-async-storage/async-storage'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { strings } from '../../lang/Lang'


function Index(props) {
    const [products, setProducts] = useState()
    const [login, setLogin] = useState(false)
    const [status, setStatus] = useState(strings.categoryOrderInfo)

    useEffect(async () => {
        loginStatus()
    }, [])


    const loginStatus = async () => {
        const login = await AsyncStorage.getItem('@user_token')
        if (!login) {
            props.history.push('/login')
        } else {
            setLogin(true)
            getProducts()
        }
    }

    const getProducts = async () => {
        const response = await api.get('api/products', {
            params: {
                categoryID: props.location.state.id,
                companyID: props.location.state.companyID
            }
        })
        if (response.data.status) {
            setProducts(response.data.data)
        }
    }

    const deleteCategory = async () => {
        if (products.length == 0) {
            const response = await api.delete('api/delete-category', {
                data: {
                    categoryID: props.location.state.id,
                    companyID: props.location.state.companyID
                }
            })
            if (response.data.status) {
                props.history.goBack()
            } else {
                setStatus(strings.deleteProductsFirst)
            }
        } else {
            setStatus(strings.deleteProductsFirst)
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    async function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            products,
            result.source.index,
            result.destination.index
        )

        setStatus(false)
        setProducts(items)
    }

    const saveProductsOrder = async () => {
        if (!status) {
            const response = await api.put('api/update-products-order', {
                productList: products,
                companyID: props.location.state.companyID,
                categoryID: props.location.state.id
            })
            if (response.data.status) {
                setStatus(strings.orderUpdated)
            } else {
                setStatus(strings.somethingWentWrong)
            }
        } else {
            setStatus(strings.noOrderChange)
        }
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "#3F65D4" : "white",
        color: isDragging ? "white" : "#858796",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const grid = 8;

    return (
        <>
            <h1 className="h3 mb-4 text-gray-800">
                <button onClick={() => props.history.goBack()} className="btn btn-primary btn-icon-split mr-3">
                    <span className="text">{strings.back}</span>
                </button>{strings.category}: <b>{login ? props.location.state.name : null}</b></h1>
            <div className="row">
                <div className="col-6">
                    <ul className="list-group">
                        {
                            products ?
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {products.map((element, index) => (
                                                    <Draggable key={element._id} draggableId={element._id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <Link className="category-a"
                                                                to={{
                                                                    pathname: '/edit-product',
                                                                    state: {
                                                                        name: element.productName,
                                                                        id: element._id,
                                                                        companyID: props.location.state.companyID,
                                                                        categoryID: props.location.state.id,
                                                                        detail: element.detail,
                                                                        price: element.price,
                                                                        image: element.image
                                                                    }
                                                                }}>
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    {element.productName}
                                                                    {provided.placeholder}
                                                                </div>
                                                            </Link>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                : null
                        }
                    </ul>
                </div>

                <div className="col-6">
                    {
                        login ?
                            <div className="col-6">
                                <Link className="btn btn-primary btn-icon-split" to={{ pathname: '/add-product', state: { id: props.location.state.id, companyID: props.location.state.companyID, categoryName: props.location.state.name } }}>
                                    <span className="text"><b>{strings.addProduct}</b></span>
                                </Link>
                                <br />
                                <div onClick={() => saveProductsOrder()} className="btn btn-success btn-icon-split mt-2">
                                    <span className="text action-button">{strings.saveProductOrder}</span>
                                </div>
                                <br />
                                <Link className="btn btn-dark btn-icon-split mt-2" to={{
                                    pathname: '/edit-category', state: {
                                        categoryID: props.location.state.id,
                                        companyID: props.location.state.companyID,
                                        name: props.location.state.name,
                                        image: props.location.state.image
                                    }
                                }}>
                                    <span className="text action-button">{strings.editCategory}</span>
                                </Link>

                                <br />
                                <div onClick={() => deleteCategory()} className="btn btn-danger btn-icon-split mt-2">
                                    <span className="text action-button">{strings.deleteCategory}: {props.location.state.name}</span>
                                </div>
                            </div>
                            : null
                    }
                    <div className="mt-4">
                        <h2 className="categories-status-text">{status ? status : null}</h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index