import { useEffect, useState } from 'react'
import { axiosInstance as api } from '../../utils/server'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { withRouter } from 'react-router-dom'
import { Link } from "react-router-dom"
import { strings } from '../../lang/Lang'
import Login from './Login'


function Register(props) {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [error, setError] = useState()

    useEffect(() => {
        loginStatus()
    }, [])

    const tryRegister = async () => {
        if (name && email && password) {
            const response = await api.post('register', {
                name,
                email,
                password
            })
            const payload = await response.data
            if (payload.status) {
                await AsyncStorage.setItem('@user_token', payload.token)
                await AsyncStorage.setItem('@user_id', payload.id)
                await AsyncStorage.setItem('@user_name', payload.name)
                await AsyncStorage.setItem('@user_email', payload.email)
                props.history.replace('/')
            } else {
                setError(payload.message)
            }
        } else {
            setError(strings.fillData)
        }

    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        tryRegister()
    }

    const loginStatus = async () => {
        const login = await AsyncStorage.getItem('@user_token')
        if (login) {
            props.history.replace('/')
        }
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">{strings.register}</h1>
                                        </div>
                                        <form className="user" onSubmit={handleSubmit}>
                                            <div className="form-group row">
                                                <div className="col-sm-12 mb-3 mb-sm-0">
                                                    <input type="text"
                                                        className="form-control form-control-user"
                                                        value={name} onChange={e => setName(e.target.value)}
                                                        Name="form-control form-control-user"
                                                        placeholder={strings.name} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="form-control form-control-user" value={email} onChange={e => setEmail(e.target.value)}
                                                    placeholder={strings.email} />
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-12 mb-3 mb-sm-0">
                                                    <input type="password" className="form-control form-control-user"
                                                        value={password} onChange={e => setPassword(e.target.value)}
                                                        placeholder={strings.password} />
                                                </div>
                                            </div>
                                            <p>
                                                {error}
                                            </p>
                                            <button className="btn btn-primary btn-user btn-block">
                                                {strings.register}
                                            </button>
                                        </form>
                                        <p className="mt-3 text-justify">
                                            {strings.haveAcc}
                                            <Link className="login-a"
                                                to={{
                                                    pathname: '/login'
                                                }}>
                                                {strings.logIn}
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="contact-teknozip">
                        <a href="mailto:contact@teknozip.com" className="pointer">contact@teknozip.com</a>
                    </p>
                </div>

            </div>

        </div>
    )
}

export default withRouter(Register)
