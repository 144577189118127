import { useState, useEffect } from 'react'
import { axiosInstance as api, BASE_URL as server } from '../../utils/server'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { strings } from '../../lang/Lang'


function EditCategory(props) {
    const [category, setCategoryName] = useState()
    const [image, setImage] = useState()
    const [status, setStatus] = useState(false)
    const [uploading, setUpload] = useState(false)
    const [uploaded, setUploaded] = useState(false)


    useEffect(async () => {
        loginStatus()
        if (props.location.state.name) {
            setCategoryName(props.location.state.name)
        }
        if (props.location.state.image) {
            setImage(props.location.state.image)
        }
    }, [])

    const loginStatus = async () => {
        const login = await AsyncStorage.getItem('@user_token')
        if (!login) {
            props.history.push('/login')
        }
    }

    const updateCategory = async () => {
        if (category) {
            setStatus(strings.updating)
            setUpload(true)
            if (uploaded) {
                const response = await api.put('api/update-category', {
                    categoryName: category,
                    categoryID: props.location.state.categoryID,
                    companyID: props.location.state.companyID,
                    categoryImage: image
                })
                if (response.data.status) {
                    props.history.push('/')
                }
            } else {
                const response = await api.put('api/update-category', {
                    categoryName: category,
                    categoryID: props.location.state.categoryID,
                    companyID: props.location.state.companyID
                })
                if (response.data.status) {
                    props.history.push('/')
                }
            }
        } else {
            setStatus(strings.fillData)
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        updateCategory()
    }

    const addPhoto = async (e) => await getBase64(e.target.files[0])

    const getBase64 = (file) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setImage(reader.result)
            setUploaded(true)
            return reader.result
        };
        reader.onerror = function (error) {
            console.log("Error: ", error)
        }
    }

    return (
        <>
            <h1 className="h3 mb-4 text-gray-800">
                <button disabled={uploading} onClick={() => props.history.goBack()} className="btn btn-primary btn-icon-split mr-3">
                    <span className="text">{strings.back}</span>
                </button> {strings.updateCategory}
            </h1>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-6">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">{strings.categoryName}</span>
                            </div>
                            <input type="text" className="form-control" value={category} onChange={e => setCategoryName(e.target.value)} />
                        </div>

                        <button disabled={uploading} className="btn btn-primary btn-icon-split mt-2">
                            <span className="text">{strings.updateCategory}</span>
                        </button>
                    </div>

                    <div className="col-6">
                        <div className="form-group">
                            <label for="exampleFormControlFile1">{strings.categoryImage}</label>
                            <input type="file" accept="image/*" className="form-control-file" disabled={uploading} onChange={addPhoto} />
                        </div>
                        <img className="product-image" src={server + 'uploads/' + image} />
                    </div>

                </div>
            </form>
        </>
    )
}

export default EditCategory