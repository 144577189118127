import { useEffect, useState } from 'react'
import { axiosInstance as api } from '../../utils/server'
import { Link } from "react-router-dom"
import AsyncStorage from '@react-native-async-storage/async-storage'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { strings } from '../../lang/Lang'

function Index(props) {
    const [categories, setCategories] = useState()
    const [login, setLogin] = useState(false)
    const [status, setStatus] = useState(strings.categoryOrderInfo)

    useEffect(async () => {
        getCategories()
        loginStatus()
    }, [])

    const getCategories = async () => {
        const response = await api.get('api/', {
            params: {
                companyID: props.location.state.id
            }
        })
        if (response.data.status) {
            setCategories(response.data.data.categories)
        }
    }


    const loginStatus = async () => {
        const login = await AsyncStorage.getItem('@user_token')
        if (!login) {
            props.history.push('/login')
        } else {
            setLogin(true)
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    async function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            categories,
            result.source.index,
            result.destination.index
        )

        setStatus(false)
        setCategories(items)
    }

    const saveCategoryOrder = async () => {
        if (!status) {
            const response = await api.put('api/update-category-order', {
                companyID: props.location.state.id,
                categories
            })
            if (response.data.status) {
                setStatus(strings.orderUpdated)
            } else {
                setStatus(strings.somethingWentWrong)
            }
        } else {
            setStatus(strings.noOrderChange)
        }
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "#3F65D4" : "white",
        color: isDragging ? "white" : "#858796",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const grid = 8;

    return (
        <>
            <h1 className="h3 mb-4 text-gray-800">
                <button onClick={() => props.history.goBack()} className="btn btn-primary btn-icon-split mr-3">
                    <span className="text">{strings.back}</span>
                </button>
                {strings.categories} - <b>{login ? props.location.state.name : null} </b>
                <Link to={{ pathname: "https://cafe.teknozip.com/" + '/' + props.location.state.id }} target="_blank">
                    <span className="text action-button">{strings.goWebsite}</span>
                </Link>
            </h1>
            <div className="row mb-4">
                <div className="col-6">
                    <ul className="list-group">
                        {
                            categories ?
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {categories.map((element, index) => (

                                                    <Draggable key={element._id} draggableId={element._id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <Link className="category-a"
                                                                to={{
                                                                    pathname: '/products', state: {
                                                                        name: element.categoryName,
                                                                        id: element._id,
                                                                        companyID: props.location.state.id,
                                                                        image: element.categoryImage
                                                                    }
                                                                }}>
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    {element.categoryName}
                                                                    {provided.placeholder}
                                                                </div>
                                                            </Link>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                : null
                        }
                    </ul>
                </div>

                <div className="col-6">
                    {
                        login ?
                            <>
                                <Link className="btn btn-primary btn-icon-split" to={{ pathname: '/add-category', state: { id: props.location.state.id } }}>
                                    <span className="text action-button">{strings.createCategory}</span>
                                </Link>
                                <br />
                                <div onClick={() => saveCategoryOrder()} className="btn btn-success btn-icon-split mt-2">
                                    <span className="text action-button">{strings.saveCategoryOrder}</span>
                                </div>
                            </> : null
                    }
                    <div className="mt-4">
                        <h2 className="categories-status-text">{status ? status : null}</h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index