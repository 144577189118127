import { useState, useEffect } from 'react'
import { axiosInstance as api, BASE_URL as server } from '../../utils/server'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { strings } from '../../lang/Lang'


function EditProduct(props) {
    const [name, setName] = useState()
    const [price, setPrice] = useState()
    const [detail, setDetail] = useState()
    const [image, setImage] = useState()
    const [status, setStatus] = useState(false)
    const [uploading, setUpload] = useState(false)
    const [uploaded, setUploaded] = useState(false)

    useEffect(() => {
        if (props.location.state.name) {
            setName(props.location.state.name)
        }
        if (props.location.state.price) {
            setPrice(props.location.state.price)
        }
        if (props.location.state.detail) {
            setDetail(props.location.state.detail)
        }
        if (props.location.state.image) {
            setImage(props.location.state.image)
        }
    }, [])

    useEffect(async () => {
        loginStatus()
    }, [])

    const loginStatus = async () => {
        const login = await AsyncStorage.getItem('@user_token')
        if (!login) {
            props.history.push('/login')
        }
    }

    const updateProduct = async () => {
        if (name && price && detail) {
            setStatus(strings.creating)
            setUpload(true)
            if (uploaded) {
                const response = await api.put('api/update-product', {
                    productID: props.location.state.id,
                    productName: name,
                    companyID: props.location.state.companyID,
                    categoryID: props.location.state.categoryID,
                    price,
                    detail,
                    image
                })
                if (response.data.status) {
                    props.history.goBack()
                } else {
                    setStatus(response.err)
                }
            } else {
                const response = await api.put('api/update-product', {
                    productID: props.location.state.id,
                    productName: name,
                    companyID: props.location.state.companyID,
                    categoryID: props.location.state.categoryID,
                    price,
                    detail
                })
                if (response.data.status) {
                    props.history.goBack()
                } else {
                    setStatus(response.err)
                }
            }
        }
        else {
            setStatus(strings.fillData)
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        updateProduct()
    }

    const deleteProduct = async () => {
        const response = await api.delete('api/delete-product', {
            data: {
                productID: props.location.state.id,
                companyID: props.location.state.companyID,
                categoryID: props.location.state.categoryID,
            }
        })
        if (response.data.status) {
            props.history.goBack()
        }
    }

    const addPhoto = async (e) => await getBase64(e.target.files[0])

    const getBase64 = (file) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setUploaded(true)
            setImage(reader.result)
            return reader.result
        };
        reader.onerror = function (error) {
            console.log("Error: ", error)
        }
    }

    const changeUpdateLang = (element) => {
        if (props.location.state.name[element]) {
            setName(props.location.state.name[element])
        } else {
            setName('')
        }
        if (props.location.state.detail[element]) {
            setDetail(props.location.state.detail[element])
        } else {
            setDetail('')
        }
    }

    return (
        <>
            <h1 className="h3 mb-4 text-gray-800">
                <button disabled={uploading} onClick={() => props.history.goBack()} className="btn btn-primary btn-icon-split mr-3">
                    <span className="text">{strings.back}</span>
                </button> {strings.updateProduct}
            </h1>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{strings.productName}</span>
                                    </div>
                                    <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{strings.productPrice}</span>
                                    </div>
                                    <input type="number" className="form-control" value={price} onChange={e => setPrice(e.target.value)} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{strings.productDetails}</span>
                                    </div>
                                    <textarea className="form-control" rows="3" value={detail} onChange={e => setDetail(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <button disabled={uploading} className="btn btn-primary btn-icon-split mmt-2">
                            <span className="text action-button">{strings.updateProduct}</span>
                        </button>

                        <br />

                        <div onClick={() => uploading ? null : deleteProduct()} className="btn btn-danger btn-icon-split mt-2">
                            <span className="text action-button">{strings.deleteProduct}: {name}</span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group mt-2">
                            <label>{strings.categoryImage}</label>
                            <input type="file" accept="image/*" className="form-control-file" disabled={uploading} onChange={addPhoto} />
                        </div>
                        <img className="product-image" src={server + '/uploads/' + image} />
                    </div>
                </div>
            </form>
        </>
    )
}

export default EditProduct