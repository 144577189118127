import { useState, useEffect } from 'react'
import { axiosInstance as api } from '../../utils/server'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { strings } from '../../lang/Lang'


function AddProduct(props) {
    const [name, setName] = useState()
    const [price, setPrice] = useState()
    const [detail, setDetail] = useState()
    const [image, setImage] = useState()
    const [status, setStatus] = useState(false)
    const [uploading, setUpload] = useState(false)

    useEffect(async () => {
        loginStatus()
    }, [])

    const loginStatus = async () => {
        const login = await AsyncStorage.getItem('@user_token')
        if (!login) {
            props.history.push('/login')
        }
    }

    const createProduct = async () => {
        if (name && price && image) {
            setStatus(strings.creating)
            setUpload(true)
            const response = await api.post('api/create-product', {
                productName: name,
                companyID: props.location.state.companyID,
                categoryID: props.location.state.id,
                price,
                detail: detail ? detail : '',
                image
            })
            if (response.data.status) {
                props.history.goBack()
            } else {
                setStatus(response.err)
            }
        }
        else {
            setStatus(strings.fillData)
        }

    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        createProduct()
    }

    const addPhoto = async (e) => await getBase64(e.target.files[0])

    const getBase64 = (file) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setImage(reader.result)
            return reader.result
        };
        reader.onerror = function (error) {
            console.log("Error: ", error)
        }
    }

    return (
        <>

            <h1 className="h3 mb-4 text-gray-800">
                <button disabled={uploading} onClick={() => props.history.goBack()} className="btn btn-primary btn-icon-split mr-3">
                    <span className="text">{strings.back}</span>
                </button>{strings.category}: {props.location.state.categoryName}</h1>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{strings.productName}</span>
                                    </div>
                                    <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{strings.productPrice}</span>
                                    </div>
                                    <input type="number" className="form-control" value={price} onChange={e => setPrice(e.target.value)} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{strings.productDetails}</span>
                                    </div>
                                    <textarea className="form-control" rows="3" value={detail} onChange={e => setDetail(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-6">
                        <div className="form-group">
                            <label>{strings.categoryImage}</label>
                            <input type="file" accept="image/*" className="form-control-file" disabled={uploading} onChange={addPhoto} />
                        </div>
                    </div>

                    <button disabled={uploading} className="btn btn-danger btn-icon-split ml-2 mt-2">
                        <span className="text">{strings.addProduct}</span>
                    </button>

                </div>
            </form>
            <div className="mt-5">
                <h2>{status ? status : null}</h2>
            </div>
        </>
    )
}

export default AddProduct