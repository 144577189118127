import Login from './screens/Auth/Login'
import Register from './screens/Auth/Register'
import Index from './screens/Home/Index'
import Categories from './screens/Home/Categories'
import Menu from './components/Menu'
import Products from './screens/Home/Products'
import AddCategory from './screens/Home/AddCategory'
import AddProduct from './screens/Home/AddProduct'
import EditCategory from './screens/Home/EditCategory'
import EditProduct from './screens/Home/EditProduct'
import AddCompany from './screens/Admin/AddCompany'
import ListCompanies from './screens/Admin/ListCompanies'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"


function App() {
  return (
    <>
      <Router>
        <Switch>
          <body id="page-top">
            <div id="wrapper">
              <Menu />
              <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                  <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    <h3 className="mt-3 mr-auto ml-auto">TEKNOZİP QR <i className="fas fa-qrcode"></i></h3>
                  </nav>
                  <div className="container-fluid">
                    <Route exact path="/" component={Index} />
                    <Route path="/register" component={Register} />
                    <Route path="/login" component={Login} />
                    <Route path="/company" component={Categories} />
                    <Route path="/products" component={Products} />
                    <Route path="/add-category" component={AddCategory} />
                    <Route path="/add-product" component={AddProduct} />
                    <Route path="/edit-category" component={EditCategory} />
                    <Route path="/edit-product" component={EditProduct} />
                    <Route path="/product-details" component={AddCategory} />
                    <Route path="/add-company" component={AddCompany} />
                    <Route path="/list-companies" component={ListCompanies} />
                  </div>
                </div>
              </div>
            </div>
          </body>
        </Switch>
      </Router>
    </>
  )
}


export default App