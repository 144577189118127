import { useState, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { axiosInstance as api } from '../../utils/server'
import { Link } from "react-router-dom"
import { strings } from '../../lang/Lang'

function ListCompanies(props) {
    const [companies, setCompanies] = useState()

    useEffect(() => {
        login()
        getCompanies()
    }, [])

    const login = async () => {
        const login = await AsyncStorage.getItem('@user_token')
        if (!login) {
            props.history.push('/login')
        }
    }


    const getCompanies = async () => {
        const user_id = await AsyncStorage.getItem('@user_id')
        const response = await api.get('api/companies', {
            params: {
                users_ID: user_id
            }
        })
        if (response.data.status) {
            setCompanies(response.data)
        }
    }

    return (
        <>
            <h1 className="h3 mb-4 text-gray-800">{strings.manageCompanies}</h1>
            <p>{strings.selectCompany}</p>
            <ul className="companies-list">
                {
                    companies ?
                        companies.data.map(element => {
                            return (
                                <li key={element._id} className="nav-item">
                                    <Link className="nav-link company-name" to={{
                                        pathname: '/edit-company', state: {
                                            id: element._id,
                                            name: element.name,
                                            date: element.created_at,
                                            trial: element.trial
                                        }
                                    }}>
                                        <h4>{element.name}</h4>
                                    </Link>
                                </li>
                            )
                        }) : null
                }
            </ul>

        </>
    )
}

export default ListCompanies